import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

import { graphql } from 'gatsby';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
  .frame .social.twitter:hover,
  .frame .social.twitter:focus,
  .frame .social.website:hover,
  .frame .social.website:focus,
  .frame .social.email:hover,
  .frame .social.email:focus {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    opacity: 0.8;
    z-index: 1;
    cursor: pointer;
  }
  .frame .social.twitter:hover i,
  .frame .social.twitter:focus i,
  .frame .social.website:hover i,
  .frame .social.website:focus i,
  .frame .social.email:hover i,
  .frame .social.email:focus i {
    opacity: 1;
    font-size: 150px;
    margin: 25% 25%;
  }

  .frame {
    width: 100%;
    height: 350px;
    border-radius: 3%;
    overflow: hidden;
    position: relative;
  }
  .frame .picture {
    width: 100%;
    height: 100%;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 600ms cubic-bezier(0.075, 0.52, 0.165, 1);
    filter: grayscale(100%);
  }
  .frame .social {
    position: absolute;
    right: 2vw;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 2;
  }
  .frame .social a {
    display: contents;
  }
  .social i {
    color: white;
    margin: 7px;
    opacity: 0;
  }
  .frame .social.twitter {
    bottom: 140px;
    background-color: #55acee;
  }
  .frame .social.website {
    bottom: 80px;
    background-color: #007bb5;
  }
  .frame .social.email {
    bottom: 20px;
    background-color: #ffdd40;
  }
  .frame:hover .picture,
  .frame.focus .picture {
    transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: scale(1.3) rotate(6deg);
  }

  .name {
    text-transform: uppercase;
  }

  .bio {
    font-size: 80%;
    color: gray;
  }

  .team-member {
    width: 25%;
    padding: 1em;
    margin-bottom: 1em;
  }

  .team {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
  }

  @media all and (max-width: 1280px) {
    .team-member {
      width: 33%;
    }
  }

  @media all and (max-width: 1080px) {
    .team-member {
      width: 50%;
    }
  }

  @media all and (max-width: 750px) {
    .team {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }

    .team-member {
      width: 100%;
    }

    .frame {
      height: 300px;
    }
  }
`;

interface AuthorProps {
  data: {
    allAuthorYaml: {
      id: string;
      name: string;
      website?: string;
      mail?: string;
      twitter?: string;
      location?: string;
      // eslint-disable-next-line @typescript-eslint/camelcase
      profile_image?: {
        childImageSharp: {
          fluid: any;
        };
      };
      bio?: string;
      avatar: {
        childImageSharp: {
          fluid: any;
        };
      };
    };
  };
}

const About: React.FC<AuthorProps> = props => {
  const authors = props.data.allAuthorYaml.edges;

  return (
    <IndexLayout>
      <Helmet>
        <title>Über uns</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader>
              <PostFullTitle>Über uns</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h2>Was wir machen</h2>
                <p>
                  Das Team von Computingeducation entwickelt hochwertige, kostenlose Lernmaterialien
                  für den Informatikunterricht und darüber hinaus. Gleichzeitig werden Workshops,
                  Videotutorials oder Fortbildungen für Lehrkräfte angeboten. Dabei wird stets wert
                  auf einfache Handhabbarkeit, eine ansprechende optische Gestaltung und einen
                  möglichst hohen Lernerfolg gelegt.
                </p>
                <p>
                  Wir sind stets interessiert an neuen spannenden Ansätzen, wie informatische Ideen
                  und Konzepte anschaulich, greifbar, kreativ und spaßig vermittelt werden können.
                  Wir freuen uns über Ihre Vorschläge und Anregungen!
                </p>
                <h2>Das Computingeducation Team</h2>
                <div className="team">
                  {authors.map((author, index) => {
                    return (
                      <div className="team-member" key={index}>
                        <div className="frame">
                          <div
                            className="picture"
                            style={{
                              backgroundImage:
                                'url("' + author.node.avatar.children[0].fixed.src + '")',
                            }}
                          ></div>
                          {author.node.twitter && (
                            <a href={'https://twitter.com/' + author.node.twitter} target="_blank">
                              <div className="social twitter">
                                <i className="fa fa-twitter"></i>
                              </div>
                            </a>
                          )}

                          {author.node.website && (
                            <a href={author.node.website} target="_blank">
                              <div className="social website">
                                <i className="fa fa-globe"></i>
                              </div>
                            </a>
                          )}
                          {author.node.mail && (
                            <a href={'mailto:' + author.node.mail} target="_blank">
                              <div className="social email">
                                <i className="fa fa-envelope"></i>
                              </div>
                            </a>
                          )}
                        </div>
                        <Link to={'/author/' + author.node.id} className="name">
                          {author.node.name}
                        </Link>
                        <div className="bio">{author.node.bio}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </PostFullContent>
          </article>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default About;

export const query = graphql`
  query {
    allAuthorYaml {
      edges {
        node {
          id
          name
          twitter
          website
          mail
          bio
          avatar {
            children {
              ... on ImageSharp {
                fixed(quality: 90) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
